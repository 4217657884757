import adminLayout from '@/layouts/Admin'
import httpAxios from '@/utils/http-axios.js'

export default {
    name: 'PuzzlesCreate',
    data() {
        return {
            puzzle: {
                name: null
            },
            fileCoverImage: null,
            fileImage: null,
            fileAudio: null
        }
    },
    components: {
        adminLayout
    },
    methods: {
        createPuzzle() {
            const self = this
            let formData = new FormData()

            if(self.puzzle.name) formData.append('name', self.puzzle.name)
            if(self.fileCoverImage) formData.append('cover_image', self.fileCoverImage)
            if(self.fileImage) formData.append('image', self.fileImage)
            if(self.fileAudio) formData.append('audio', self.fileAudio)
            
            httpAxios({
                url: self.$backendUrl + '/api/v1/puzzles',
                method: 'POST',
                data: formData
            }).then(function() {
                self.$router.push({ name: 'admin.puzzles' })

                self.notifySuccess()
            })
        },
        handleFileCoverImageUpload(e) {
            let files = e.target.files || e.dataTransfer.files
            if (!files.length) {
                return this.fileCoverImage = null
            }

            this.fileCoverImage = files[0]
        },
        handleFileImageUpload(e) {
            let files = e.target.files || e.dataTransfer.files
            if (!files.length) {
                return this.fileImage = null
            }

            this.fileImage = files[0]
        },
        handleFileAudioUpload(e) {
            let files = e.target.files || e.dataTransfer.files
            if (!files.length) {
                return this.fileAudio = null
            }

            this.fileAudio = files[0]
        }
    }
}